.mr-2 {
  margin-right: 10px;
}

.mb-2 {
  margin-bottom: 10px;
}

.skeleton-sm-mb {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.05) !important;
  margin-bottom: 16px;
}

.skeleton-md {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.05) !important;
}