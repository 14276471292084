@import 'constants';
@import 'fonts';

.s1 {
    font-size: 40px;
    font-weight: bolder;
}

.s2 {
    font-size: 30px;
    font-weight: bold;
}

.s3 {
    font-size: 25px;
    font-weight: bold;
}

.s4 {
    font-size: 18px;
    font-weight: normal;
}

.s5 {
    font-size: 14px;
    font-weight: normal;
}

.bold {
    font-weight: bold !important;
}

.faded {
    color: $lightBlue;
}

.number {
  font-family: 'Merriweather Sans', sans-serif;
  font-family: 'Montserrat Alternates', sans-serif, Tofu;
  font-optical-sizing: auto;
}

@media screen and (max-width: $tablet-min-width) {
    .bold {
      font-weight: 500 !important;
  }
    .s1 {
      font-size: 28px;
      font-weight: bold;
  }

  .s2 {
      font-size: 24px;
      font-weight: bold;
  }

  .s3 {
      font-size: 18px;
      font-weight: bold;
  }

  .s4 {
      font-size: 14px;
      font-weight: normal;
  }

  .s5 {
      font-size: 12px;
      font-weight: normal;
  }
}
