@import 'constants';

// /* ==========================================================================
//   Global styles
//   ========================================================================== */

body,
html {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}

@-moz-document url-prefix() {

  select,
  select:-moz-focusring,
  select::-moz-focus-inner {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important;
    border: 0;
  }
}


// preserve toggle switch styles
// input.form-check-input,
// input.form-check-input:checked,
// input:-webkit-autofill:active {
//     -webkit-box-shadow: 0 0 0 0 transparent inset !important;
// }

/* Remove auto-filling input box backgrounds*/
// input,
// select,
// select:-webkit-autofill input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//   -webkit-box-shadow: 0 0 0 30px $white inset !important;
// }

textarea {
  min-height: 40px;
}

input:active,
input:focus,
textarea:active,
textarea:focus {
  transition: $easeInOut;
  box-shadow: $inputFocusShadow;
  border-color: transparent;
}

input:disabled {
  background-color: $dullGrey;
  border: $inputDisabledBorder;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.6;
}

select:hover,
textarea:hover,
input:hover {
  border-color: $primary;
  transition: $easeInOut;
}

button {
  border-radius: $borderRadius;
  box-sizing: border-box;
  // border-width: 0;
  // border: none;
  color: $white;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  transition: $easeInOut;
  font-weight: 500;
  white-space: nowrap;
  height: 30px;
  outline: none;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  // box-shadow: $buttonShadow;

  &:active,
  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    background-color: $white;
    border: 2px solid $lighterGrey;
    // border: $inputDisabledBorder;
    box-shadow: none;
    color: $lighterGrey;
    cursor: not-allowed;
  }
}

.a-button {
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 16px;
  padding: 6px 12px 6px 12px;
  border-radius: $borderRadius;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  text-decoration: none;
}

.a-button:hover {
  opacity: 0.8;
}

.apple-icon {
  width: 16px;
  margin-left: 5px;
}

select {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: center right 10px;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  height: 34px;
  padding-right: 35px;
  border-color: transparent;
  width: 100% !important;
  color: $black;
}

select:active,
select:focus {
  // border-color: $primary;
  border-color: transparent;
  box-shadow: $inputFocusShadow;
}

// for required invalid input
.ng-invalid.ng-touched:not(form) {

  input,
  textarea {
    border-color: $danger;
    box-shadow: none;
    // border-color: rgba(231, 76, 60, 0.75);
    border-width: 2px;
  }

  textarea:focus,
  input:focus,
  select:focus {
    border-color: $danger;
    box-shadow: none;
    // border-color: rgba(231, 76, 60, 0.75);
    border-width: 2px;
  }
}

.a-link {
  color: $darkPurple;
  font-family: 'Poppins', sans-serif !important;
  text-decoration: none;
}

.a-link:hover {
  color: $secondary;
  text-decoration: underline;
}

.text-danger {
  font-size: 14px;
  padding: 0 !important;
  margin: 0 !important;
  color: $danger !important;
}

.error-message {
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.form-check-input:checked {
  background-color: $primary !important;
  border-color: $primary !important;
}

// .toast {
//   display: inline-block;
//   min-width: 350px;
//   min-height: 51px;
// }

._low-emphasis {
  color: $grey;
}

._close-icon {
  padding: 0.5rem;
  cursor: pointer;
  width: 34px;
  height: 34px;
  background-color: rgba(244, 244, 244, 0.85);
  border-radius: 50%;
  margin-left: 0.5rem;
  transition: $easeInOut;

  &.dark-theme {
    background-color: $opaqueLight;
  }
}

._close-icon:hover {
  opacity: 0.7;
}

@media (max-width: $tablet-min-width) {
  .introjs-tooltip {
    max-width: 300px !important;

    width: 300px !important;
  }
}
