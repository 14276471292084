/* ==========================================================================
    Color Variables
    ========================================================================== */
$white: #fff;
$white-20: rgb(252, 252, 252);
$white-30: rgb(231, 233, 233);
$white-90: rgb(203, 222, 255);
$offWhite: rgb(250, 253, 254);
$opaque: #ffffff21;
$opaqueLight: rgba(255, 255, 255, 0.05);
$opaqueDark: rgba(0, 0, 0, 0.2);
$opaqueGray: rgb(109, 127, 146);

$black: #0A2540;
$black-10: #232b33;

$grey: #999;
$dullGrey: #e0e0e0;
$mediumGrey: #545B64;
$lightGrey: rgb(121, 121, 121);
$lighterGrey: #ced4da;

$gray: #616161;
$slateGrey: #414552;
$mediumGray: rgb(201, 202, 206);
$lightGray: rgb(235, 238, 241);
$lighterGray: rgb(247, 248, 250);

$blue: #1488ee;
$blue-10: #1888ff6f;
$blue-20: darken($blue, 5%);
$blue-30: #4269FC;

$lightBlue: #516f90;
$lightBlueV2: #4B53BC;
$lightBlueV2Opaque: #4e56c1;
$lightTeal: #CFF5F5;
$teal: #80DFF7;
$teal-50: #00BCE7;
$brightTeal: #06CBCB;
$gradient-teal-background: rgb(12, 249, 233);

$navy: #092540;
$navy-90: #000018;

$purple-0: rgba(232, 216, 252, 0.3);
$purple-10: rgb(106, 115, 244);
$purple-90: rgb(41, 7, 152);
$purple: #635AFF;
$lightPurple: #854DFF;
$fadedPurple: #4b53bcac;
$darkPurple: #4B53BC;

$yellow: #FFC71E;

$orange: #FF7A59;
$orangeOpaque: rgba(255, 122, 89, 0.7);
$green: #1EA371;
$green-20: #00992B;
$neonGreen: #39FF14;
$neonGreenOpaque: rgba(57, 255, 20, 0.1);
$greenOpaque: rgba(30, 163, 113, 0.7);
$darkGreen: #135564;
$lightGreen: #0D4557;

$pink: #FF85FF;

$red-0: rgba(255, 57, 2, 0.214);
$red-5: #ee534297;
$red-10: #ed4343;
$red: #800000;

$gradient-blue: linear-gradient(to left top, #001241, #0D4557);
$gradient-blue-reverse: linear-gradient(to left top, #0D4557, #001241);

$gradient-teal: linear-gradient(to left top, rgb(12, 249, 233), rgb(12, 186, 249));

/* ==========================================================================
    Theme Variables
    ========================================================================== */

$primary: $purple;
$secondary: $lightBlueV2Opaque;
$danger: $red;
$success: $green;
$warning: $yellow;
$light: $white;
$dark: $black;
$info: $blue;
$bright: $brightTeal;
$link: $lightPurple;
$disabled: $dullGrey;
$dismiss: $mediumGrey;

/* ==========================================================================
      Shadow Variables
    ========================================================================== */

$floatingShadow: 0 5px 10px rgba(0, 0, 0, 0.1),
  0 20px 20px rgba(0, 0, 0, 0.05);
$headerShadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
$cardShadow: 0 1px 10px 0 rgba(0, 28, 36, 0.1);
$simpleShadow: 0 1px 5px 0 rgba(0, 28, 36, 0.1);
$cardSpreadShadow: 0 1px 20px 10px rgba(0, 28, 36, 0.2);
$selectShadow: 0 0 0 1px rgba(87, 86, 95, 0.35);
$buttonShadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
$inputInvalidShadow: 0 0 0 1px rgba(194, 11, 11, 0.15);
$inputFocusShadow: 0 0 0 1px $primary;
$inputActiveShadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.15);
$pipaTourHighlightShadow: rgba(253, 253, 253, 0.8) 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
$popoverShadow: 0 0 0 1px #8898aa1a, 0 15px 35px #31315d1a, 0 5px 15px #00000014;
$pressableShadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
$pressableHoverShadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 2px 5px 0px, rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;

/* ==========================================================================
      Border Variables
      ========================================================================== */

$thinBorder: 1px solid #eaeded;
$inputBorder: 1px solid $lighterGrey;
$inputDisabledBorder: 1px solid #e0e0e0;
$borderRadius: 50px;

/* ==========================================================================
      Transition Variables
      ========================================================================== */

$easeInOut: all 0.2s ease-in-out;

/* ==========================================================================
      Layout Constants
      ========================================================================== */

$headerHeight: 50px;

$zIndexHighest: 999;
$zIndexHigh: 99;
$zIndexMedium: 98;
$zIndexLow: 97;

$cardPadding: 20px;

/* ==========================================================================
      Media Queries Constants
      ========================================================================== */

$mobile-min-width: 30em; // 480px
$mobile-max-width: 47.999em; // 767px
$tablet-min-width: 48em; // 768px
$tablet-max-width: 63.999em; // 1023px
$desktop-min-width: 74.999em; // 1199px