.flex {
  display: flex;
}

.flex__center--start {
  display: flex;
  align-items: center;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex__row--space-between {
  display: flex;
  justify-content: space-between;
}

.flex__row--space-around {
  display: flex;
  justify-content: space-around;
}

.flex__row--space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.flex__row--end {
  display: flex;
  justify-content: flex-end;
}

.flex__row--wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex__column {
  display: flex;
  flex-direction: column;
}

.flex__column--center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex__column--space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex__column--space-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.flex__column--space-evenly {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.flex__column--start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.flex__column--end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.fx-1 {
  display: flex;
  flex: 1;
}