@import 'constants';

.introjs-button {
  display: flex !important;
  align-items: center !important;
  border-radius: $borderRadius !important;
  box-sizing: border-box !important;
  border-width: 0 !important;
  border: none !important;
  color: $white !important;
  cursor: pointer !important;
  font-size: 14px !important;
  line-height: 1 !important;
  transition: $easeInOut !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
  height: 30px !important;
  outline: none !important;
  padding: 0 12px 0 12px !important;
  text-shadow: none !important;
  font-family: 'Poppins', sans-serif !important;
}

.introjs-nextbutton {
  background-color: $primary !important;
}

.introjs-prevbutton {
  background-color: transparent !important;
  color: $dark !important;
}

.introjs-disabled {
  background-color: $white !important;
  border: $inputDisabledBorder !important;
  box-shadow: none !important;
  color: transparent !important;
  border: none !important;
}

.introjs-tooltiptext {
  font-family: 'Poppins', sans-serif !important;
}

.introjs-helperLayer {
  box-shadow: $pipaTourHighlightShadow;
}

.pipaTooltip {
  box-shadow: none !important;
}

.introjs-tooltipbuttons {
  &.dark-theme {
    border-top: 1px solid $opaqueLight !important;
    outline: none !important;
  }
}

// .introjs-tooltipReferenceLayer {}

.introjs-tooltip {
  max-width: 350px !important;
  width: 350px !important;

  //dark theme
  &.dark-theme {
    background-color: $dark !important;
    color: $white !important;
    border: 1px solid $opaqueLight !important;

    .introjs-arrow {
      display: none !important;
    }

    .introjs-skipbutton {
      color: $offWhite !important;
    }

    .introjs-tooltipbuttons {
      border-top: 1px solid $opaqueLight !important;

      .introjs-nextbutton {
        outline: none !important;
      }

      .introjs-nextbutton::after {
        outline: none !important;
      }
    }
  }
}