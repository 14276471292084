@import 'constants';

body::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* Disable scrollbar Chrome/Safari/Webkit */
}

::-webkit-scrollbar {
  background: rgba(255, 166, 0, 0);
  /* color of the tracking area */
  width: 0 !important;
}

::-webkit-scrollbar-track {
  background: rgba(255, 166, 0, 0);
  /* color of the tracking area */
  width: 0 !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.082);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

/* Customize the scrollbar for WebKit-based browsers (Chrome, Safari) */
.scrollable-popover::-webkit-scrollbar {
  width: 5px;
  /* Set the width of the scrollbar */
}

.scrollable-popover::-webkit-scrollbar-track {
  background-color: lighten($lightBlue, 20%) !important;
  /* Background of the scrollbar track */
  border-radius: 10px;
  /* Rounded corners for the scrollbar track */
}

.scrollable-popover::-webkit-scrollbar-thumb {
  background-color: lighten($lightBlue, 20%) !important;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for the scrollbar thumb */
}

/* Customize scrollbar for Firefox */
.scrollable-popover {
  scrollbar-width: thin;
  /* Set scrollbar width */
  scrollbar-color: lighten($lightBlue, 20%) #f1f1f1;
  /* Set the thumb and track color */
}