.pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation-delay: 0.5s;
}

._easeTopToBottom {
  position: relative;
  animation: easeTopToBottom;
  opacity: 1;
  animation-iteration-count: 1;
  animation-duration: 450ms;
}

._easeLeftToRight {
  position: relative;
  animation: easeLeftToRight;
  opacity: 1;
  animation-iteration-count: 1;
  animation-duration: 450ms;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.35;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes easeTopToBottom {
  from {
    top: -30px;
  }

  to {
    top: 0px;
  }
}

@keyframes easeTopToBottom {
  from {
    top: -30px;
  }

  to {
    top: 0px;
  }
}

@-webkit-keyframes easeLeftToRight {
  from {
    left: -30px;
  }

  to {
    left: 0px;
  }
}

@keyframes easeLeftToRight {
  from {
    left: -30px;
  }

  to {
    left: 0px;
  }
}
