@import 'constants';

.ng-select-light-theme {
  background-color: #fff;
}


.ng-select-container {
  min-width: 200px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: rgba(152, 84, 235, 0.1);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: rgba(152, 84, 235, 0.2);
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: $primary;
  box-shadow: $inputFocusShadow;
}

.ng-select .ng-clear-wrapper {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 16px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: none !important;
  border-style: none !important;
  border-width: 0 !important;
}

.ng-select .ng-arrow-wrapper {
  background-image: url('/assets/icons/arrow-down-gray.svg');
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center right 10px;
  margin-top: 2px;
}

.ng-value-container {
  color: $dark;
}

.ng-placeholder {
  font-weight: 300 !important;
}